import React from "react"
import PropTypes from "prop-types"
import { Router } from "@reach/router"
import axios from "axios";

import Post from "../templates/post"


class PreviewPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            post: null,
        }
    }
    async componentDidMount() {
        if (this.props.uuid) {
            const id = this.props.uuid;
            // endpoints params
            const browseParams = {
                filter: `uuid:${this.props.uuid}`,
                formats: `html`,
            }

            // retrieve a brief list of posts and pages
            const post = (await axios.get(`https://ghost-preview-api.herokuapp.com/post/${id}`)).data

            if (post) {
                this.setState({ post })
            }
        }
    }
    render() {
        // when ghost answers back
        if (this.state.post !== null) {
            let data = {}
            // match the expected structure
            delete Object.assign(data, this.state.post, {['ghostPost']: this.state.post['post'] })['post']
            const location = this.props.location
            return <Post data={data} location={location} />
        }
        return null
    }
}

PreviewPage.propTypes = {
    uuid: PropTypes.string,
    location: PropTypes.object, // passed down by @reach/router
}

const Preview = () => (
    <Router>
        <PreviewPage path="/preview/:uuid" />
    </Router>
)

export default Preview